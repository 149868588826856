<template>
    <a-card title="" :bordered="false">
        <div class="init-loading" v-if="infoLoading || categroyLoading">
            <a-spin size="large" />
        </div>
        <a-form-model class="edit-form" ref="editForm" :model="formField" :rules="formRules">
            <a-form-model-item label="稿件分类" prop="catid" v-if="formField.isContribute">
                <a-select v-model="formField.catid" style="width: 180px" :disabled="[2, 8, 9, 10, 60].indexOf(formField.status) >= 0">
                    <a-select-option :value="item.catid" v-for="item in categroy" :key="item.catid">
                        {{ item.title }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="文章标题" prop="title">
                <a-input
                    size="large"
                    type="text"
                    placeholder="请输入文章标题"
                    v-model="formField.title"
                    :disabled="[2, 8, 9, 10, 60].indexOf(formField.status) >= 0"
                >
                </a-input>
                <div slot="extra" v-if="!formField.isContribute">原标题：{{ formField.originTitle }}</div>
            </a-form-model-item>
            <a-form-model-item label="正文" prop="content">
                <tinymce-editor v-model="formField.content" :readonly="[2, 8, 9, 10, 60].indexOf(formField.status) >= 0" />
            </a-form-model-item>
            <a-form-model-item v-if="[2, 8, 9, 10, 60].indexOf(formField.status) < 0">
                <a-button class="submit-btn" size="large" type="primary" :loading="saveLoading" @click="formSubmit"
                    >保存</a-button
                >
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import tinymceEditor from "@/components/tinymce/editor";
export default {
    components: {
        tinymceEditor,
    },
    props: {
        articleId: [String, Number],
    },
    data() {
        return {
            infoLoading: true,
            categroyLoading: true,
            saveLoading: false,
            categroy: [], // 用户选择的类别
            routeWriteFree: false,
            formField: {
                id: "",
                catid: "",
                title: "",
                content: "",
                status: 0,
                originTitle: "", // 原标题
                isContribute: "", //是否自由投稿
            },
            formRules: {
                catid: [{ required: true, message: "请选择稿件分类", trigger: "change" }],
                title: [{ required: true, message: "请输入文章标题", trigger: "change" }],
                content: [
                    { required: true, message: "文章内容不能为空", trigger: "change" },
                    { validator: this.tinymceImgLoad },
                ],
            },
        };
    },
    watch: {
        // 路由变化刷新
        $route(to) {
            if (this.$route.name == "writeFree") {
                // 自由投稿
                this.routeWriteFree = true;
                this.formField = {
                    id: "",
                    catid: "",
                    title: "",
                    content: "",
                    status: 0,
                    isContribute: true, //是否自由投稿
                };
            } else {
                this.getArticleInfo(this.$route.params.id);
            }
        },
        // 编辑弹窗文章id变化
        articleId(val) {
            this.getArticleInfo(val);
        },
    },
    created() {
        // 获取用户类别分类
        this.$store.dispatch("user/getCategory").then((res) => {
            let cateArr = [
                {
                    catid: "",
                    title: "请选择稿件分类",
                },
            ];
            this.categroy = cateArr.concat(res);
            this.categroyLoading = false;
            this.infoLoading = false;
        });
        if (this.$route.name == "writeFree") {
            // 自由投稿
            this.routeWriteFree = true;
            this.formField.isContribute = true;
        } else {
            this.getArticleInfo(this.$route.params.id || this.articleId);
        }
    },
    methods: {
        // 编辑器图片上传
        tinymceImgLoad(rule, value, callback) {
            if (value.indexOf('class="load-img"') > 0) {
                callback(new Error("请检查图片是否上传成功"));
            }
            callback();
        },
        // 获取文章信息
        getArticleInfo(id) {
            if (!id) return;
            this.$api.userManuscriptsInfo({ id }).then((res) => {
                if (res.status) {
                    this.infoLoading = false;
                    this.formField.id = id;
                    this.formField.status = res.data.status;
                    this.formField.catid = res.data.catid;
                    this.formField.title = res.data.title;
                    this.formField.originTitle = res.data.origin_title;
                    this.formField.content = res.data.content;
                    this.formField.isContribute = res.data.is_contribute == 1;
                }
            });
        },
        // 保存投稿
        formSubmit(e) {
            e.preventDefault();
            const { formField, routeWriteFree } = this;
            this.$refs.editForm.validate((ispass) => {
                if (ispass) {
                    // 验证通过
                    this.saveLoading = true;
                    // 自由投稿
                    if (routeWriteFree) {
                        this.$api
                            .userManuscriptsFreeTougao({
                                catid: formField.catid,
                                title: formField.title,
                                content: formField.content,
                            })
                            .then((res) => {
                                this.saveLoading = false;
                                if (res.status) {
                                    this.$message.success({ content: "投稿成功", key: "messageKey" });
                                    setTimeout(() => {
                                        // 判断是否为弹窗操作
                                        if (this.articleId) {
                                            this.$emit("success", this.articleId);
                                        } else {
                                            this.$router.replace({ name: "writeArticle" });
                                        }
                                    }, 1000);
                                } else {
                                    this.$message.warn({
                                        content: res.msg,
                                        key: "messageKey",
                                    });
                                }
                            });
                    } else {
                        this.$api
                            .userManuscriptsTougao({
                                id: formField.id,
                                catid: formField.catid,
                                title: formField.title,
                                content: formField.content,
                            })
                            .then((res) => {
                                this.saveLoading = false;
                                if (res.status) {
                                    this.$message.success({ content: "投稿成功", key: "messageKey" });
                                    setTimeout(() => {
                                        // 判断是否为弹窗操作
                                        if (this.articleId) {
                                            this.$emit("success", this.articleId);
                                        } else {
                                            this.$router.replace({ name: "writeArticle" });
                                        }
                                    }, 1000);
                                } else {
                                    this.$message.warn({
                                        content: res.msg,
                                        key: "messageKey",
                                    });
                                }
                            });
                    }
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.init-loading {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.submit-btn {
    margin-top: 20px;
}
/deep/ .ant-form-item {
    margin-bottom: 8px;
}
</style>
