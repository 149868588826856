<template>
    <div class="tinymce-editor">
        <editor
            ref="editor"
            v-model="content"
            :init="init"
            @onChange="editorChange"
            @onPaste="editorPaste"
            @onKeyUp="editorKeyup"
        />
        <div class="tinymce-editor__wordcount">已输入{{ wordCount }}字符</div>
    </div>
</template>

<script>
import tinymce from "tinymce/tinymce";
// 主题
import "tinymce/themes/silver/theme";
// 图标
import "tinymce/icons/default/icons";
// 载入插件
import "tinymce/plugins/code"; //源码
import "tinymce/plugins/image"; //图片上传
import "tinymce/plugins/fullscreen"; //全屏
import "tinymce/plugins/paste"; //黏贴插件
//import 'tinymce/plugins/wordcount'; //字数统计
// 自定义插件
import "./plugins/base64img";
import { img2base64 } from "@/common/media";
import editor from "@tinymce/tinymce-vue";
export default {
    name: "TinymceEditor",
    components: {
        editor,
    },
    props: {
        value: {
            type: String,
            default: "",
        },
        // 只读
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(val) {
            this.content = val || "";
            this.wordCount = this.getWordsCount(this.content);
            tinymce.editors[0].setMode(this.readonly ? "readonly" : "design");
        },
    },
    data() {
        return {
            content: "",
            wordCount: 0,
            init: {
                selector: `#tinymceAAAAA222`,
                placeholder: "请输入文章正文",
                language_url: "/tinymce/langs/zh_CN.js",
                language: "zh_CN",
                height: 500,
                skin_url: "/tinymce/skins/ui/oxide",
                branding: false, // 是否禁用“Powered by TinyMCE”
                menubar: false, //顶部菜单栏显示,
                statusbar: false, // 隐藏编辑器底部的状态栏
                content_style: `
					body {margin: 5px;padding: 10px;line-height: 1.4;color:#333;overflow-x: hidden;}
					img{max-width: 99%;}
					.mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before{left: auto;color:rgba(0,0,0,.4)}
					p {margin: 0 0 5px;line-height: 30px;}
					.load-img{display: none}
				`,
                block_formats: "Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Header 5=h5;Header 6=h6", //段落显示
                plugins: "code fullscreen image paste base64img",
                // toolbar: 'undo redo | bold italic forecolor | alignleft aligncenter alignright alignjustify | quickimage | fullscreen',
                toolbar:
                    "undo redo | bold italic forecolor | alignleft aligncenter alignright alignjustify | quickimage | fullscreen",
                paste_data_images: false, //允许黏贴图片上传
                paste_as_text: false, //默认粘贴为文本
                entity_encoding: "raw", // 所有字符都将以非实体形式保存
                invalid_elements: "a", //过滤标签
                // 黏贴过滤img
                paste_preprocess: function(plugin, args) {
                    let content = args.content;
                    content = content.replace(/<img.*?>/gi, ""); //移除img
                    content = content.replace(/<br>/gi, "</p><p>").replace(/<br \/>/gi, "</p><p>"); //替换br
                    content = content.replace(/<p>\s+<\/p>/gi, "").replace(/<p><\/p>/gi, ""); //移除空行
                    args.content = content;
                },
                images_upload_handler: this.upLoadImg,
            },
        };
    },
    created() {
        this.content = this.value;
        this.wordCount = this.getWordsCount(this.content);
    },
    mounted() {
        tinymce.init({});
    },
    beforeDestroy() {
        // window.tinymce.get()[0].destroy();
    },
    methods: {
        editorChange() {
            this.$emit("input", this.content);
            setTimeout(() => {
                if (this.imgUploadError) {
                    let editerContent = tinymce.editors[0].getContent();
                    editerContent = editerContent.replace(/<img src="data:image.*? \/>/gi, "");
                    editerContent = editerContent.replace(/<img src="blob:.*? \/>/gi, "");
                    tinymce.editors[0].setContent(editerContent);
                    this.imgUploadError = false;
                }
            }, 100);
        },
        editorPaste(a, b, c, d) {
            // 黏贴操作
        },
        // 图片上传后操作
        editorImageHandle(imgSuccess) {
            let editerContent = tinymce.editors[0].getContent();
            if (imgSuccess) {
                editerContent = editerContent.replace(/class="load-img"/gi, "");
                editerContent = editerContent.replace(
                    /<img class="load-img-place" src="https:\/\/img\.d1xz\.net\/d\/2021\/10\/6177d4edbb440\.jpg" \/>/gi,
                    ""
                );
            } else {
                editerContent = editerContent.replace(/<img src="data:image.*? \/>/gi, "");
                editerContent = editerContent.replace(/<img src="blob:.*? \/>/gi, "");
            }
            tinymce.editors[0].setContent(editerContent);
        },
        // 键盘起
        editorKeyup() {
            this.wordCount = this.getWordsCount(this.content);
        },
        upLoadImg(blobInfo, succFun, failFun) {
            let file = blobInfo.blob();
            if (file.size >= 2 * 1024 * 1024) {
                failFun("请上传小于2M的图片");
                // 撤销自动生成图片代码
                this.imgUploadError = true;
                return;
            }
            if (["image/jpeg", "image/jpg", "image/png", "image/gif"].indexOf(file.type) < 0) {
                failFun("只允许上传jpg、png、gif三种类型的图片");
                return false;
            }
            // 上传base64
            img2base64(file).then((image) => {
                this.$api
                    .imageBase64({
                        especially: "d1xz",
                        data: image.code,
                        suffix: image.ext,
                    })
                    .then((res) => {
                        if (res.status) {
                            succFun(res.data.url);
                            this.editorImageHandle(true);
                        } else {
                            failFun("请重新上传");
                            this.editorImageHandle(false);
                        }
                    });
            });
        },
        // 字数统计
        getWordsCount(text) {
            let str = String(text);
            // 替换html标签
            str = str.replace(/<\/?.+?\/?>/g, '');
            //先将回车换行符做特殊处理
            str = str.replace(/(\r\n+|\s+|　+)/g, '龘');
            //处理英文字符数字，连续字母、数字、英文符号视为一个单词
            str = str.replace(/[\x00-\xff]/g, 'm');
            //合并字符m，连续字母、数字、英文符号视为一个单词
            str = str.replace(/m+/g, '*');
            //去掉回车换行符
            str = str.replace(/龘+/g, '');
            //返回字数
            return str.length;
        }
    },
};
</script>
<style scoped lang="less">
.tinymce-editor {
    position: relative;
    &__wordcount {
        position: absolute;
        z-index: 3;
        right: 0;
        bottom: 0;
        font-size: 12px;
        line-height: 1.6;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        padding: 4px 10px;
    }
}
</style>
